<template>
  <div class="col-12 col-md-8">
    <div class="card shadow rounded-lg">
      <div class="card-header fw-bold text-center fs-5">
        Welcome, <em>AI Avatar</em>!
      </div>
      <div class="card-body py-5">
        <Logo />
        <div class="text-center">
          <div class="d-flex justify-content-center gap-2">
            <div>
              <select
                class="form-select"
                aria-label="Select Languages"
                v-model="credentials.lang"
              >
                <option value="en">English</option>
                <option value="vi">Vietnamese</option>
                <option value="zh-TW">Traditional Chinese</option>
                <option value="fr">French</option>
                <option value="ja">Japanese</option>
              </select>
            </div>
            <div class="btn btn-primary" @click="login()">
              Start
              <span v-if="loading"><i class="fa fa-spin fa-spinner"></i></span>
            </div>
          </div>

          <div v-if="errorMessage">
            <small class="text-danger">
              {{ errorMessage }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";

export default {
  name: "index",

  beforeCreate() {
    document.title = `${process.env.VUE_APP_TITLE} Welcome`;
  },
  data: () => ({
    credentials: {
      username: "username",
      password: "password",
      lang: "en",
    },

    loading: false,
    errorMessage: null,
  }),

  components: {
    Logo,
  },
  methods: {
    async login() {
      this.loading = true;
      this.$store
        .dispatch("auth/login", this.credentials)
        .then(() => this.$router.push({ path: "/dashboard" }))
        .catch((err) => {
          this.errorMessage = err.message;
          this.loading = false;
        });
    },
  },
};
</script>
